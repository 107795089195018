<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        변경이력조회
      </h4>
      <hr>
      <div class="ml-2 mr-2">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="mb-1"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div class="ml-2 mr-2 pb-1">
        <b-row>
          <b-col cols="6">
          </b-col>
          <b-col
            cols="6"
            lg="6"
            md="12"
            sm="12"
            class=""
          >
            <b-form-group
              class="font-small-3"
              label="시작일시"
              label-for="period"
              label-cols-md="2"
            >
              <b-input-group
                name="period"
                class="calendar-size font-small-3"
              >
                <b-form-datepicker
                  v-model="periodFrom"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="시작일"
                  aria-controls="example-input"
                  size="sm"
                  :max="periodTo"
                />
                <div class="pl-1 pr-1">
                  ~
                </div>
                <b-form-datepicker
                  v-model="periodTo"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="종료일"
                  aria-controls="example-input"
                  size="sm"
                  :min="periodFrom"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- Table -->
      <b-table
        responsive
        show-empty
        hover
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchApprovalLogs"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClickHandler"
      >
        <template #cell(no)="data">
          {{ data.item.idx }}
        </template>
        <template #cell(category)="data">
          <div style="min-width: 7rem">
            {{ data.item.category }}
          </div>
        </template>
        <template #cell(categoryDetail)="data">
          <div style="min-width: 9rem">
            <div>{{ data.item.categoryDetail.split('-')[0] }}</div>
            <div>{{ data.item.categoryDetail.split('-')[1] }}</div>
          </div>
        </template>
        <template #cell(userid)="data">
          <div style="min-width: 6rem">
            {{ data.item.userid }}
          </div>
        </template>
        <template #cell(targetUserid)="data">
          <div style="min-width: 6rem">
            {{ data.item.targetUserid }}
          </div>
        </template>
        <template #cell(tran)="data">
          <div style="min-width: 4rem">
            {{ data.item.tran }}
          </div>
        </template>
        <template #cell(asisContent)="data">
          <div style="min-width: 14rem; max-width: 30rem; text-align: left">
            <div v-for="item in data.item.asisContent.split('|')" :key="item"
                 v-b-tooltip.hover.bottom.v-danger="item">
              <li>{{ truncateText(item, 20) }}</li>
            </div>
          </div>
        </template>
        <template #cell(tobeContent)="data">
          <div style="min-width: 14rem; max-width: 30rem; text-align: left">
            <div v-for="(item, index) in data.item.tobeContent.split('|')" :key="item"
                 :style="{ color: item === data.item.asisContent.split('|')[index] ? '' : 'yellow' }"
                 v-b-tooltip.hover.bottom.v-danger="item">
              <li>{{ truncateText(item, 20) }}</li>
            </div>
          </div>
        </template>
        <template #cell(createdAt)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 9rem"
          >
            {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BAvatar,
  BMedia,
  VBTooltip, VBModal, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_APPROVAL_LOGS } from '@/store/logs/action'

import vSelect from 'vue-select'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import * as moment from "moment-timezone";
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'
const fmt3 = 'YYYY-MM-DD HH:mm:ss'

const logStore = createNamespacedHelpers('logStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      connectedUsers: [],
      socket: null,
      perPage: 10,
      pageOptions: [10, 30, 50],
      sortBy: 'idx',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      periodFrom: '',
      periodTo: '',
      fields: [
        { key: 'no', sortable: false, label: 'No.' },
        // { key: 'site', sortable: false, label: '사이트' },
        { key: 'category', sortable: false, label: '유형' },
        { key: 'categoryDetail', sortable: false, label: '상세' },
        { key: 'userid', sortable: false, label: '수행자' },
        { key: 'targetUserid', sortable: false, label: '변경대상' },
        { key: 'tran', sortable: false, label: '구분' },
        { key: 'asisContent', sortable: false, label: '원본' },
        { key: 'tobeContent', sortable: false, label: '변경' },
        { key: 'createdAt', sortable: false, label: '일시' },
      ],
      sortOptions1: [
        { label: '전체', value: null },
        { label: '회원정보', value: 'users' },
        { label: '기본환경설정', value: 'basicSettings' },
        { label: 'IP관리', value: 'ipSettings' },
      ],
      sortOptions2: [
        { label: '전체', value: null },
        { label: '정상회원', value: 'active' },
        { label: '휴먼회원', value: 'human' },
        { label: '차단회원', value: 'block' },
      ],
    }
  },
  computed: {
    ...logStore.mapGetters({
      fetchApprovalLogs: 'fetchApprovalLogs',
    }),
    logStore() {
      return this.$store.state.logStore.fetchApprovalLogs
    },
  },
  created() {
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
  },
  mounted() {
    this.init()
    this.fetchData()
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods: {
    ...logStore.mapActions({
      $fetchApprovalLogs: FETCH_APPROVAL_LOGS,
    }),
    ...settingsStore.mapGetters({
      fetchSetSiteAdmin: 'fetchSetSiteAdmin',
    }),
    init() {
      this.periodFrom = moment().subtract(7, 'days').format(fmt3)
      this.periodTo = moment().format(fmt3)
    },
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchApprovalLogs({
        site: mySite,
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      })
      this.totalRows = this.fetchApprovalLogs.length
    },
    onRowSelected(items) {
      this.selected = items
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClickHandler(record) {
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      } else {
        return text.substring(0, maxLength) + '...'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.tooltip .tooltip-inner{
  max-width: 500px !important;
  width: 400px !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
